/* eslint-disable no-restricted-imports */
import version from './config.json';

// Do not change these values, use a .env file instead. An example file has been supplied.

const base = new URL(process.env.REACT_APP_SERVER_URL_BASE ?? 'https://devapi.caretaker.dk');
const noTopDomain = /(.*)\./.exec(base.hostname)?.[1];

const config = {
    ...version,
    NAME: noTopDomain,
    SERVER_URL_BASE: base.toString(),
    SERVER_URL: base.toString() + 'api',
    NODE_TEST_API: process.env.REACT_APP_NODE_TEST_API ?? 'https://reactnode.caretaker.dk'
} as const;

export default config;
