import { centralizedSnackbarReducer } from './hooks/redux-use-centralized-snackbar';
import { configureStore } from '@reduxjs/toolkit';
import { dictionaryReducer } from './hooks/redux-use-dictionary';
import { fetchReducer } from './hooks/fetch-hooks/use-fetch';
import { historyNavigateReducer } from './hooks/extended-hooks/use-history-navigate';
import { mapReducer } from '../components/map/redux-slice';
import { permReducer } from '../components/restricted/reducer';
import { reduxFetchReducer } from './hooks/fetch-hooks/use-redux-fetch';
import { userReducer } from './hooks/redux-use-user';

const configure = () =>
    configureStore({
        reducer: {
            centralizedSnackbar: centralizedSnackbarReducer,
            dictionary: dictionaryReducer,
            reduxFetch: reduxFetchReducer,
            fetch: fetchReducer,
            user: userReducer,
            historyNavigate: historyNavigateReducer,
            map: mapReducer,
            permissions: permReducer
        },
        devTools: true
    });

let store: ReturnType<typeof configure>;

const getStore = () => {
    if (store === undefined) resetStore();
    return store;
};

export const resetStore = () => {
    store = configure();
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred til reducer dictionarien ovenover
export type AppDispatch = typeof store.dispatch;

export default getStore;
