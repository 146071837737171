import './App.css';
import './shared/flex.css';
import 'devextreme/dist/css/dx.light.css';

import OuterRouter, { SignInRouter } from './router';
import { Suspense, lazy, useEffect } from 'react';
import useUser, { IUserState } from './shared/hooks/redux-use-user';

import AppProviders from './App-providers';
import BreadCrumbProvider from './shared/contexts/breadcrumb-context';
import CookieHandler from './shared/utils/cookie-handler';
import GeneralErrorBoundary from './shared/error-handlers/boundary-generel';
import Localize from './shared/utils/localization';
import MapPortal from './components/map/map-portal';
import PersistentDrawerLeft from './components/layout/burger-menu-mobile';
import { SnackbarDisplayer } from './shared/hooks/redux-use-centralized-snackbar';
import { isMobile } from 'react-device-detect';
import { useCli } from './cli';

const DesktopCss = lazy(() => import('./style/desktop'));
const MobileCss = lazy(() => import('./style/mobile'));

const App = () => {
    useEffect(() => {
        Localize();
    }, []);

    window.addEventListener('scroll', (e) => {
        if (document.body.style.position === 'fixed') {
            e.preventDefault();
            window.scrollTo(0, 0);
        }
    });

    return (
        <AppProviders>
            {' '}
            {/* Collection of all the app's providers */}
            <OuterLayout /> {/* Wrapper of all screens */}
            <Suspense fallback={<></>}>{isMobile ? <MobileCss /> : <DesktopCss />}</Suspense>
            <PortalComponents />
            <SnackbarDisplayer />{' '}
            {/* Component to display snackbars in the main window, even from other react trees (fx map overlays) */}
        </AppProviders>
    );
};

const OuterLayout = () => {
    // Sign-in and navigation here, to put it outside the router suspense
    const { user, dispatchAuthenticateFromCookie } = useUser();

    useEffect(() => {
        let cookie = CookieHandler.read('auth');
        if (cookie) {
            cookie = JSON.parse(cookie as string);
            dispatchAuthenticateFromCookie(cookie as IUserState);
        }
    }, [dispatchAuthenticateFromCookie]);

    // Return
    if (user.active)
        return (
            <BreadCrumbProvider>
                <PersistentDrawerLeft>
                    <GeneralErrorBoundary>
                        <OuterRouter />
                    </GeneralErrorBoundary>
                </PersistentDrawerLeft>
            </BreadCrumbProvider>
        );
    return <SignInRouter />;
};

/**
 * A collection of components that either has to be anchored at the bottom of the page, or has to be located in a place that never rerenders externally
 * Fx used for components that break if ever unmounted, like the portal for <CaretakerMap />
 */
const PortalComponents = () => {
    useCli();
    return (
        <>
            <MapPortal />
        </>
    );
};

export default App;
