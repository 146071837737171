import { DefaultLayerKeys, FeatureProperty, drawRectangle } from '../../feature-utility';
import { FeatureStyleKey, layers, map } from '../..';
import { useCallback, useRef } from 'react';

import CTLayer from '../../CTLayer';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { IDictionary } from '../../../../../shared/utils/types';
import Polygon from 'ol/geom/Polygon';
import { distance } from 'ol/coordinate';
import { floors } from './create-layer-gui';

/**
 * Shift features to display all floors stacked on each other when in "lokaler" menu.
 * Destructured return value is stable and should never trigger a rerender.
 */
const useShiftRooms = () => {
    const shiftedRef = useRef<{ feature: Feature; offset: number; }[]>([]);
    const hiddenRef = useRef<{ feature: Feature; oldStyleKeys: FeatureStyleKey | FeatureStyleKey[]; }[]>([]);
    const boundariesRef = useRef<{ features: Feature<Polygon>[]; layer?: CTLayer; }>({ features: [] });

    const unshiftRooms = useCallback(() => {
        shiftedRef.current.forEach((x) => x.feature.getGeometry()!.translate(0, -x.offset));
        shiftedRef.current = [];
        hiddenRef.current.forEach(({ feature, oldStyleKeys }) => feature.set(FeatureProperty.StyleKeys, oldStyleKeys));
        hiddenRef.current = [];
        boundariesRef.current.features.forEach((f) => boundariesRef.current.layer?.getSource()?.removeFeature(f));
        boundariesRef.current = { features: [] };
    }, []);

    const shiftRooms = useCallback(() => {
        const urlLayers = layers.getUrlLayers();
        const enhLayer = urlLayers[DefaultLayerKeys.Enhed];
        const lokLayer = urlLayers[DefaultLayerKeys.Lokale];

        const childFeatures: IDictionary<Feature<Geometry>[]> = {};
        const lokFeatures: IDictionary<Feature<Geometry>[]> = {};

        // Index all features by enhId
        Object.keys(urlLayers)
            .filter((k) => k !== DefaultLayerKeys.Enhed && k !== DefaultLayerKeys.Lokale)
            .forEach((k) =>
                urlLayers[k].layer.getSource()?.forEachFeature((f: Feature<Geometry>) => {
                    const id = f.get(FeatureProperty.Search)?.parents?.enhId ?? 'no-parent-id';
                    childFeatures[id] ??= [];
                    childFeatures[id].push(f);
                })
            );

        // Index loks seperatly
        lokLayer.layer.getSource()?.forEachFeature((f) => {
            const id = f.get(FeatureProperty.Search)?.parents?.enhId ?? 'no-parent-id';
            childFeatures[id] ??= [];
            lokFeatures[id] ??= [];
            childFeatures[id].push(f);
            lokFeatures[id].push(f);
        });

        // Find closest enh with lok children
        let enhId: number | null = null;
        let enhFeature: Feature<Geometry> | null = null;
        let enhDistance: number = 9999999999;
        const center = map!.getView().getCenter()!;
        enhLayer.layer.getSource()?.forEachFeature((f: Feature<Geometry>) => {
            // Enh has itself as parent id because reasons. Actual feature id does not correspond to parent ids used by children
            const id = f?.get(FeatureProperty.Search)?.parents?.enhId;
            if (lokFeatures[id] == null) return;

            const coords = f.getGeometry()!.getClosestPoint(center);
            const d = distance(center, coords);
            if (d < enhDistance) {
                enhDistance = d;
                enhId = id;
                enhFeature = f;
            }
        });

        // Hide other features
        Object.keys(childFeatures)
            .filter((k) => k !== enhId?.toString())
            .forEach((k) =>
                childFeatures[k].forEach((f) => {
                    hiddenRef.current.push({ feature: f, oldStyleKeys: f.get(FeatureProperty.StyleKeys) });
                    f.set(FeatureProperty.StyleKeys, FeatureStyleKey.Hidden);
                })
            );

        if (enhId == null) return;

        // Find lok boundary
        const extent: number[] = [9999999999, 9999999999, -9999999999, -9999999999];
        lokLayer.layer.getSource()?.forEachFeature((f: Feature<Geometry>) => {
            const parent = f?.get(FeatureProperty.Search)?.parents?.enhId;
            if (parent !== enhId) return;

            const lokFloor = floors.indexOf(f.get(FeatureProperty.Data).floor);
            if (lokFloor === -1) return;

            const ext = f.getGeometry()!.getExtent();
            ext[0] < extent[0] && (extent[0] = ext[0]);
            ext[1] < extent[1] && (extent[1] = ext[1]);
            ext[2] > extent[2] && (extent[2] = ext[2]);
            ext[3] > extent[3] && (extent[3] = ext[3]);
        });

        if (extent[0] === 9999999999) return;

        // Shift children
        extent[0] -= 5;
        extent[1] -= 5;
        extent[2] += 5;
        extent[3] += 5;
        const offset = extent[3] - extent[1];
        const featureCount: number[] = [];
        let maxMult = -1,
            minMult = 9;
        childFeatures[enhId].forEach((f) => {
            const childFloor = f.get(FeatureProperty.Data).floor;
            const multiplier = floors.indexOf(childFloor) - 1;
            if (multiplier === -2) return;

            if (multiplier === 6) {
                console.log(f);
            }

            featureCount[multiplier] ??= 0;
            featureCount[multiplier] += 1;

            multiplier > maxMult && (maxMult = multiplier);
            multiplier < minMult && (minMult = multiplier);
            f.getGeometry()!.translate(0, offset * multiplier);
            shiftedRef.current.push({ feature: f, offset: offset * multiplier });
        });

        // Draw boundary rectangle
        for (let i = minMult; i <= maxMult; i++) {
            const count = featureCount[i] ?? 0;
            let label = '';
            label += `${enhFeature!.get(FeatureProperty.Data)?.label}`;
            label += ` - Etage: ${floors[i + 1]}`;
            label += ` - ${count} feature${count === 1 ? '' : 's'}`;
            const f = drawRectangle(
                lokLayer.layer,
                extent,
                label
            );
            f.getGeometry()?.translate(0, offset * i);
            boundariesRef.current.features.push(f);
        }
        boundariesRef.current.layer = lokLayer.layer;

        return unshiftRooms;
    }, [unshiftRooms]);

    return {
        /**
         * Shifts features on floors to show all at the same time.
         * Returns a function to unshift features. Alternatively `unshiftRooms()` can by called.
         */
        shiftRooms,

        /** Unshifts features on floors. Does nothing if no features are shifted. Alternatively the return value of `shiftRooms()` can be used. */
        unshiftRooms
    };
};

export default useShiftRooms;
