import DateFnsUtils from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { MathJaxContext } from 'better-react-mathjax';
import { Provider } from 'react-redux';
import { QueryClientProvider } from 'react-query';
import { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import da from 'date-fns/locale/da';
import defaultTheme from './shared/utils/theme';
import getStore from './shared/store';
import queryClient from './shared/utils/query-config';

export interface AppProvidersProps {
    children: ReactNode | ReactNode[];
}

const AppProviders = (props: AppProvidersProps) => (
    <Provider store={getStore()}>
        {' '}
        {/* Redux store */}
        <MathJaxContext>
            <LocalizationProvider dateAdapter={DateFnsUtils} locale={da}>
                {' '}
                {/* Mui localization */}
                <ThemeProvider theme={defaultTheme}>
                    <SnackbarProvider maxSnack={3} variant={'info'} className='ct-snackbar-provider'>
                        {' '}
                        {/* Snackbars */}
                        <QueryClientProvider client={queryClient}>
                            {' '}
                            {/* react-query config */}
                            {props.children}
                        </QueryClientProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </LocalizationProvider>
        </MathJaxContext>
    </Provider>
);

export default AppProviders;
