import { DefaultOptions, MutationCache, QueryCache, QueryClient } from 'react-query';

interface QueryClientConfig {
    queryCache?: QueryCache;
    mutationCache?: MutationCache;
    defaultOptions?: DefaultOptions;
}

const queryClientConfig: QueryClientConfig = {
    defaultOptions: {
        queries: {
            suspense: false
        },
        mutations: {}
    }
};

const queryClient = new QueryClient(queryClientConfig);
export default queryClient;
