import { Fill, Text } from 'ol/style';

import CTLayer from './CTLayer';
import { Extent } from 'ol/extent';
import Feature from 'ol/Feature';
import { FeatureStyleKey } from '.';
import { Polygon } from 'ol/geom';

export enum DefaultLayerKeys {
    Enhed = 'POLY_Ejendomme',
    Bygning = 'POLY_Bygninger',
    Lokale = 'POLY_Lokaler'
}

export enum FeatureProperty {
    Data = 'data',
    Search = 'search',
    StyleKeys = 'styleKey'
}

export const drawRectangle = (layer: CTLayer | undefined, extent: Extent, text?: string) => {
    const polygon = new Polygon([
        [
            [extent[0], extent[1]],
            [extent[0], extent[3]],
            [extent[2], extent[3]],
            [extent[2], extent[1]]
        ]
    ]);
    const feature = new Feature(polygon);
    feature.set(FeatureProperty.StyleKeys, FeatureStyleKey.Boundary);
    text !== undefined &&
        feature.set(
            'boundaryText',
            new Text({
                text,
                font: '16px "Segoe UI", Verdana, Tahoma, sans-serif',
                fill: new Fill({ color: 'black' }),
                textBaseline: 'top',
                placement: 'line'
            })
        );
    layer?.getSource()?.addFeature(feature);
    return feature;
};
