import Api, { AuthResponse } from '../networking/api';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from './redux-base-hooks';

import CookieHandler from '../utils/cookie-handler';
import { UserPermissions } from '../../components/restricted/interfaces';
import getStore from '../store';
import { useCallback } from 'react';

//#region Slice

export interface IUserState {
    active: boolean;
    stayActive: boolean;
    id: number | undefined;
    token: string | undefined;

    userName: string | undefined;
    role: string | undefined;
    team: string | undefined;
    permissions: UserPermissions[];
    [key: string]: unknown;
}

const initialState: IUserState = {
    active: false,
    stayActive: false,
    id: undefined,
    token: undefined,

    userName: undefined,
    role: undefined,
    team: undefined,
    permissions: []
};

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        authenticate: (state: IUserState, action: PayloadAction<AuthResponse>) => {
            Api.init(action.payload.token!);

            state.active = true;
            state.id = action.payload.id;
            state.token = action.payload.token;
            state.userName = action.payload.username;
            state.role = action.payload.role;
            state.team = action.payload.team;
        },

        authenticateFromCookie: (_state: IUserState, action: PayloadAction<IUserState>) => {
            Api.init(action.payload.token!);
            return action.payload;
        },

        stayActive: (state: IUserState, action: PayloadAction<boolean | undefined>) => {
            state.stayActive = action?.payload ?? !state.stayActive;
            if (state.stayActive) CookieHandler.write('auth', JSON.stringify(state));
            else if (!state.stayActive) CookieHandler.remove('auth');
        },

        signOut: () => {
            CookieHandler.remove('auth');
            return initialState;
        }
    }
});

export const userReducer = userSlice.reducer;

//#endregion Slice

//#region useUser¨

export const getUser = () => getStore().getState().user;

const useUser = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user);
    const dispatchAuthenticate = useCallback(
        (a: AuthResponse) => dispatch(userSlice.actions.authenticate(a)),
        [dispatch]
    );
    const dispatchAuthenticateFromCookie = useCallback(
        (u: IUserState) => dispatch(userSlice.actions.authenticateFromCookie(u)),
        [dispatch]
    );
    const dispatchStayActive = useCallback((b?: boolean) => dispatch(userSlice.actions.stayActive(b)), [dispatch]);
    const dispatchSignOut = useCallback(() => dispatch(userSlice.actions.signOut()), [dispatch]);
    return { user, dispatchAuthenticate, dispatchAuthenticateFromCookie, dispatchStayActive, dispatchSignOut };
};

export default useUser;

//#endregion useUser
