import { Outlet, Route, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';

import LoadingScreen from './screens/loading';
import { UserPermissions } from './components/restricted/interfaces';
import { getRestrictedRoute } from './components/restricted/routing';
import { useInitHistoryNavigate } from './shared/hooks/extended-hooks/use-history-navigate';
import usePageHeight from './shared/hooks/use-page-height';

// Routers
const DriftRouter = lazy(() => import('./screens/drift/router'));
const MapRouter = lazy(() => import('./screens/map/router'));
const RapportRouter = lazy(() => import('./screens/rapport/router'));
const TestRouter = lazy(() => import('./screens/test/router'));
const BygRouter = lazy(() => import('./screens/byg/router'));
const ServiceRouter = lazy(() => import('./screens/service/router'));

// Screens
// const HomeOldScreen = lazy(() => import('./screens/home-old'));
const HomeScreen = lazy(() => import('./screens/home'));
// const HomePilotScreen = lazy(() => import('./screens/home-pilot-screen'));
const AdminScreen = lazy(() => import('./screens/admin-screen'));
const SettingsScreen = lazy(() => import('./screens/settings/settings-screen'));
const SignInScreen = lazy(() => import('./screens/sign-in'));
const ResetPasswordScreen = lazy(() => import('./screens/reset-password'));
const ForgotPasswordScreen = lazy(() => import('./screens/forgot-password'));
// Http codes
const Http404 = lazy(() => import('./screens/http/404'));

const OuterRouter = () => {
    useInitHistoryNavigate();
    return (
        <Suspense fallback={<LoadingScreen />}>
            <Routes>
                <Route path='/' element={<PageContainer />}>
                    {/* <Route index element={<HomeOldScreen />} /> */}
                    {/* <Route index element={<HomePilotScreen />} /> */}
                    <Route index element={<HomeScreen />} />
                    {getRestrictedRoute({
                        path: '/Drift/*',
                        element: <DriftRouter />,
                        permissions: UserPermissions.Drift
                    })}
                    {getRestrictedRoute({
                        path: '/Rapport/*',
                        element: <RapportRouter />,
                        permissions: UserPermissions.Rapport
                    })}
                    {getRestrictedRoute({ path: '/Map/*', element: <MapRouter />, permissions: UserPermissions.Map })}
                    {getRestrictedRoute({
                        path: '/Test/*',
                        element: <TestRouter />,
                        permissions: UserPermissions.Admin
                    })}
                    {getRestrictedRoute({
                        path: '/Admin',
                        element: <AdminScreen />,
                        permissions: UserPermissions.Admin
                    })}
                    {getRestrictedRoute({
                        path: '/Settings',
                        element: <SettingsScreen />,
                        permissions: UserPermissions.Settings
                    })}
                    {getRestrictedRoute({
                        path: '/Bygningsdelskort/*',
                        element: <BygRouter selection='Bygningsdelskort' />,
                        permissions: UserPermissions.Bygningsdelskort
                    })}
                    {getRestrictedRoute({
                        path: '/Syn/*',
                        element: <BygRouter selection='Syn' />,
                        permissions: UserPermissions.Syn
                    })}
                    {getRestrictedRoute({
                        path: '/Service/*',
                        element: <ServiceRouter />,
                        permissions: UserPermissions.Service
                    })}
                    {getRestrictedRoute({
                        path: '/Denied/*',
                        element: <DriftRouter />,
                        permissions: UserPermissions.Denied
                    })}
                    <Route path='*' element={<Http404 />} />
                </Route>
            </Routes>
        </Suspense>
    );
};

export const SignInRouter = () => {
    useInitHistoryNavigate();
    return (
        <Suspense fallback={<LoadingScreen />}>
            <Routes>
                <Route path='/' element={<PageContainer />}>
                    <Route index element={<SignInScreen />} />
                    <Route path='/Reset' element={<ResetPasswordScreen />} />
                    <Route path='/Forgot' element={<ForgotPasswordScreen />} />
                    <Route path='*' element={<SignInScreen />} />
                </Route>
            </Routes>
        </Suspense>
    );
};

const PageContainer = () => {
    const height = usePageHeight();
    return (
        <>
            <div id='App' style={{ height }}>
                <div id='App-child'>
                    <Outlet />
                </div>
            </div>
        </>
    );
};

export default OuterRouter;
